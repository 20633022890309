import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ children }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  return (
    <div className="main-layout">
      <Header showNavbar={showNavbar} setShowNavbar={setShowNavbar} />
      <div className="layout-content">
        <main className="main-content">{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
