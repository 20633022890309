import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function Archive() {
  const { id, filterTitle } = useParams();
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const fetchGroups = async () => {
    try {
      setGroupsLoading(true);
      const groupsResult = await axios.get(
        `https://api.jive.ir/api/Product/GetGroups`
      );
      if (groupsResult.data.success) {
        setGroups(groupsResult.data.extra);
        setGroupsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProducts = async () => {
    try {
      setProductsLoading(true);
      if (filterTitle && id) {
        const productsResult = await axios.get(
          `https://api.jive.ir/api/Product/GetProducts?groupId=${id}&filterTitle=${filterTitle}`
        );
        if (productsResult.data.success) {
          setProducts(productsResult.data.extra);
          setProductsLoading(false);
        }
      } else {
        if (filterTitle) {
          const productsResult = await axios.get(
            `https://api.jive.ir/api/Product/GetProducts?filterTitle=${filterTitle}`
          );
          if (productsResult.data.success) {
            setProducts(productsResult.data.extra);
            setProductsLoading(false);
          }
        } else {
          if (id) {
            const productsResult = await axios.get(
              `https://api.jive.ir/api/Product/GetProducts?groupId=${id}`
            );
            if (productsResult.data.success) {
              setProducts(productsResult.data.extra);
              setProductsLoading(false);
            }
          } else {
            const productsResult = await axios.get(
              `https://api.jive.ir/api/Product/GetProducts`
            );
            if (productsResult.data.success) {
              setProducts(productsResult.data.extra);
              setProductsLoading(false);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = async (groupId) => {
    if (filterTitle) {
      navigate(`/archive/${groupId}/${filterTitle}`);
      await fetchProducts();
    } else {
      if (groupId === 0) {
        navigate("/archive/0");
        await fetchProducts();
      } else {
        navigate(`/archive/${groupId}`);
        await fetchProducts();
      }
    }
  };

  return (
    <div className="archive">
      <div className="center">
        <div className="sidebar">
          {groupsLoading ? (
            Array(10)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="sidebar-item-loading">
                  <div className="input-checkbox"></div>
                  <div className="label"></div>
                </div>
              ))
          ) : (
            <>
              <div className="sidebar-item">
                <input
                  type="checkbox"
                  value={0}
                  checked={!id || id === 0 || id === "0"}
                  onChange={() => handleCheckboxChange(0)}
                  id={`groupId_${0}`}
                />
                <label htmlFor={`groupId_${0}`}>نمایش همه</label>
              </div>
              {groups.map((group) => (
                <div key={group.groupId} className="sidebar-item">
                  <input
                    type="checkbox"
                    value={group.groupId}
                    checked={id && group.groupId.toString() === id.toString()}
                    onChange={() => handleCheckboxChange(group.groupId)}
                    id={`groupId_${group.groupId}`}
                  />
                  <label htmlFor={`groupId_${group.groupId}`}>
                    {group.title}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="list">
          {productsLoading ? (
            <>
              {/* {Array(5)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="product-item-loading">
                    <div className="image"></div>
                    <div className="hover-image"></div>
                    <div className="title"></div>
                  </div>
                ))} */}
            </>
          ) : (
            products.map((item, index) => (
              <Link
                to={`/product/${item.productId}`}
                key={index}
                className="product-item">
                <img
                  src={`https://jive.ir/uploads/products/${item.imageName}`}
                  alt={item.title}
                  className="image"
                />
                {item.hoverImageName ? (
                  <img
                    src={`https://jive.ir/uploads/products/${item.hoverImageName}`}
                    alt={item.title}
                    className="hover-image"
                  />
                ) : item.videoName ? (
                  <video width="100%" height="350" controls>
                    <source
                      src={`https://dl.jive.ir/uploads/products/${item.videoName}`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={`https://jive.ir/uploads/products/${item.imageName}`}
                    alt={item.title}
                    className="hover-image"
                  />
                )}
                <h2>{item.title}</h2>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
