import axios from "axios";
import { useEffect, useState } from "react";

export default function Subscriptions() {
  const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetchData = async (subscriptionPlanId) => {
    try {
      setSubscriptionPlanLoading(true);
      const subscriptionPlanResult = await axios.get(
        `https://api.jive.ir/api/Subscription/GetSubscriptionPlans`
      );
      if (subscriptionPlanResult.data.success) {
        setSubscriptions(subscriptionPlanResult.data.extra);
        setSubscriptionPlanLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getSubscription = async (subscriptionPlanId) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  return subscriptionPlanLoading ? (
    <></>
  ) : (
    <div className="subscriptions">
      <div className="center">
        {subscriptions.length > 0 &&
          subscriptions.map((item, index) => (
            <div className="subscription-index" key={index}>
              <span>از</span>
              <strong>
                {item.price.toLocaleString()} تومان <span>/ </span>
                {item.days} <span>روزه</span>
              </strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}></div>
              <button
                type="button"
                onClick={() => getSubscription(item.subscriptionPlanId)}
                className="buy-subscription-button-plan">
                تهیه اشتراک
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
