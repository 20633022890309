import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function Product() {
  const { id } = useParams();
  const { user, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(true);
  const [subscriptionPlan, setSubscriptionPlan] = useState({});

  const fetchSubscriptionPlan = async () => {
    try {
      setSubscriptionPlanLoading(true);
      const subscriptionPlanResult = await axios.get(
        `https://api.jive.ir/api/Subscription/GetSubscriptionPlanForIndex`
      );
      if (subscriptionPlanResult.data.success) {
        setSubscriptionPlan(subscriptionPlanResult.data.extra);
        setSubscriptionPlanLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const productResult = await axios.get(
        `https://api.jive.ir/api/Product/GetProductById?productId=${id}`
      );
      if (productResult.data.success) {
        setProduct(productResult.data.extra);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchSubscriptionPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <></>
  ) : (
    <div className="product">
      <div className="center">
        <div className="sidebar">
          {subscriptionPlanLoading ? (
            <div className="subscription-index-loading">
              <span></span>
              <span></span>
              <strong></strong>
              <strong></strong>
              <div></div>
              <div></div>
              <Link className="buy-subscription-button-plan-loading"></Link>
            </div>
          ) : isAuthenticated ? (
            <>
              <div className="subscription-index">
                <img
                  src={`https://jive.ir/uploads/products/${product.imageName}`}
                  alt={product.title}
                />
                <a
                  href={`https://jive.ir/uploads/products/${product.videoName}`}
                  target="_blank"
                  className="buy-subscription-button-plan"
                  rel="noreferrer">
                  دانلود ویدئوی پیش نمایش
                </a>
                {user.haveSubscription && (
                  <a
                    href={`https://jive.ir/uploads/products/${product.fileName}`}
                    target="_blank"
                    className="buy-subscription-button-plan"
                    rel="noreferrer">
                    دانلود محصول
                  </a>
                )}
              </div>
              {!user.haveSubscription && (
                <div className="subscription-index">
                  <span>از</span>
                  <strong>
                    {subscriptionPlan.price.toLocaleString()} تومان{" "}
                    <span>/ </span>
                    {subscriptionPlan.days} <span>روزه</span>
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subscriptionPlan.description,
                    }}></div>
                  <Link
                    to="/subscriptions"
                    className="buy-subscription-button-plan">
                    تهیه اشتراک
                  </Link>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="subscription-index">
                <img
                  src={`https://jive.ir/uploads/products/${product.imageName}`}
                  alt={product.title}
                />
                <a
                  href={`https://jive.ir/uploads/products/${product.videoName}`}
                  target="_blank"
                  className="buy-subscription-button-plan"
                  rel="noreferrer">
                  دانلود ویدئوی پیش نمایش
                </a>
              </div>
              <div className="subscription-index">
                <span>از</span>
                <strong>
                  {subscriptionPlan.price.toLocaleString()} تومان{" "}
                  <span>/ </span>
                  {subscriptionPlan.days} <span>روزه</span>
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: subscriptionPlan.description,
                  }}></div>
                <Link to="/login" className="buy-subscription-button-plan">
                  تهیه اشتراک
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="player">
          <h1>{product.title}</h1>
          {product.videoName && (
            <video controls>
              <source
                src={`https://jive.ir/uploads/products/${product.videoName}`}
                type="video/mp4"
              />
              مرورگر شما از این ویدئو پشتیبانی نمی کند.
            </video>
          )}
          <h5>توضیحات</h5>
          <div
            className="postDetailFullText"
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          />
        </div>
      </div>
    </div>
  );
}
