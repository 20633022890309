import axios from "axios";
import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Home() {
  const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(true);
  const [subscriptionPlan, setSubscriptionPlan] = useState({});
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  const fetchSubscriptionPlan = async () => {
    try {
      setSubscriptionPlanLoading(true);
      const subscriptionPlanResult = await axios.get(
        `https://api.jive.ir/api/Subscription/GetSubscriptionPlanForIndex`
      );
      if (subscriptionPlanResult.data.success) {
        setSubscriptionPlan(subscriptionPlanResult.data.extra);
        setSubscriptionPlanLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGroups = async () => {
    try {
      setGroupsLoading(true);
      const groupsResult = await axios.get(
        `https://api.jive.ir/api/Product/GetGroups`
      );
      if (groupsResult.data.success) {
        setGroups(groupsResult.data.extra);
        setGroupsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubscriptionPlan();
    fetchGroups();
  }, []);

  return (
    <div className="index">
      <div className="center">
        <div className="alert-index">
          <FaStar size={25} color="#191919" />
          <p>اشتراک فوق‌العاده برای خلاقان، حالا با یک سبک جدید و هماهنگ!</p>
        </div>
        <div className="slider">
          <div className="details">
            <h2>فایل های خلاق بصورت نامحدود، همه در جیوه!</h2>
            <p>
              با یک اشتراک ساده به طیف بی نظیری از دارایی ها و الگوهای با کیفیت
              از هنرمندان جهانی دسترسی پیدا کنید.
            </p>
          </div>
          {subscriptionPlanLoading ? (
            <div className="subscription-index-loading">
              <span></span>
              <span></span>
              <strong></strong>
              <strong></strong>
              <div></div>
              <div></div>
              <Link className="buy-subscription-button-plan-loading"></Link>
            </div>
          ) : (
            <div className="subscription-index">
              <span>از</span>
              <strong>
                {subscriptionPlan.price.toLocaleString()} تومان <span>/ </span>
                {subscriptionPlan.days} <span>روزه</span>
              </strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: subscriptionPlan.description,
                }}></div>
              <Link
                to="/subscriptions"
                className="buy-subscription-button-plan">
                تهیه اشتراک
              </Link>
            </div>
          )}
        </div>
        {groupsLoading ? (
          <div className="groups-loading">
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="skeleton-group-item">
                  <div className="skeleton-title"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-image"></div>
                </div>
              ))}
          </div>
        ) : (
          groups.length > 0 && (
            <div className="groups">
              {groups.map((item, index) => (
                <Link
                  key={index}
                  to={`/archive/${item.groupId}`}
                  className="group-item">
                  <h2>{item.title}</h2>
                  <p>{item.productsCount} محصول</p>
                  <img
                    src={`https://jive.ir/uploads/groups/${item.imageName}`}
                    alt={item.title}
                    className="image"
                  />
                </Link>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
}
