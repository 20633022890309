import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  useEffect(() => {
    document.title = "نتیجه ای یافت نشد";
  }, []);

  return (
    <div className="page-404">
      <h1>404</h1>
      <p>صفحه‌ای که به دنبال آن هستید پیدا نشد.</p>
      <div className="link-container">
        <Link to="/">بازگشت به صفحه اصلی</Link>
      </div>
    </div>
  );
};

export default NotFound;
