import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setIsAuthenticated(true);

      // اضافه کردن توکن به درخواست‌های axios
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${parsedUser.token}`;
    }
  }, []);

  const login = async (mobile, password) => {
    try {
      const response = await axios.post(
        "https://api.jive.ir/api/account/login",
        {
          mobile,
          password,
        }
      );

      if (response.data.success) {
        const userData = response.data.extra;

        setUser(userData);
        setIsAuthenticated(true);
        localStorage.setItem("user", JSON.stringify(userData));

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.token}`;

        navigate("/dashboard");
      } else {
        setError(response.data.message);
        throw new Error(response.data.message || "ورود ناموفق بود");
      }
    } catch (error) {
      console.error("An error occurred during login:", error.message);
      setError(error.message);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("user");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
