import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { IoSearchSharp } from "react-icons/io5";
import axios from "axios";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
const Header = ({ showNavbar, setShowNavbar }) => {
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([]);
  const [menuLoading, setMenuLoading] = useState([]);
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const fetchGroups = async () => {
    try {
      setMenuLoading(true);
      const groupsResult = await axios.get(
        `https://api.jive.ir/api/Product/GetGroups`
      );
      if (groupsResult.data.success) {
        setGroups(groupsResult.data.extra);
        setMenuLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <header>
      <div className="search-bar">
        <button
          type="button"
          onClick={() => toggleNavbar()}
          className="toggle-navbar">
          <GiHamburgerMenu color="white" size={26} />
        </button>
        <Link to="/" className="logo">
          <img src={logo} alt="جیوه" />
        </Link>
        <div className="search-box">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="جستجو کنید..."
          />
          <IoSearchSharp
            color="white"
            size={26}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Link to="/subscriptions" className="buy-subscription-button">
          اشتراک
        </Link>
        {isAuthenticated ? (
          <>
            <Link to="/userPanel" className="search-bar-link-item">
              ناحیه کاربری
            </Link>
            <Link
              onClick={() => handleLogout()}
              className="search-bar-link-item">
              خروج
            </Link>
          </>
        ) : (
          <>
            <Link to="/login" className="search-bar-link-item">
              ورود
            </Link>
            <Link to="/register" className="search-bar-link-item">
              ثبت نام
            </Link>
          </>
        )}
      </div>
      {menuLoading ? (
        <div className="menu-loading">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      ) : (
        <div className="menu">
          <ul>
            <li>
              <Link to={`/archive`}>همه</Link>
            </li>
            {groups.length > 0 &&
              groups.map((item, index) => (
                <li key={index}>
                  <Link to={`/archive/${item.groupId}`}>{item.title}</Link>
                </li>
              ))}
          </ul>
        </div>
      )}
      {showNavbar && (
        <div className="mobile-menu">
          <div className="mobile-menu-items">
            <button
              type="button"
              onClick={() => toggleNavbar()}
              className="close-navbar">
              <IoMdClose color="white" size={26} />
            </button>
            <ul>
              <li>
                <Link to={`/archive`}>همه</Link>
              </li>
              {groups.length > 0 &&
                groups.map((item, index) => (
                  <li key={index}>
                    <Link to={`/archive/${item.groupId}`}>{item.title}</Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
