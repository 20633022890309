import { useState } from "react";
import "../../assets/styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
export default function Login() {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const { login, error } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await login(mobile, password);
      navigate("/");
    } catch (err) {
      console.error(err.message || "ورود ناموفق بود");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="center">
        <div className="form">
          <h1>ورود به سایت</h1>
          <div className="form-group">
            <label htmlFor="mobile">موبایل</label>
            <input
              type="text"
              value={mobile}
              id="mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">کلمه عبور</label>
            <input
              type="password"
              value={password}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="error-text">{error}</p>}
          <button
            type="button"
            disabled={loading}
            onClick={() => handleSubmit()}
            className="login-button">
            {loading ? "در حال ورود..." : "ورود"}
          </button>
          <p>
            حساب کاربری ندارید؟ <Link to="/register">ثبت نام</Link> کنید.
          </p>
          <p>
            کلمه عبور خود را فراموش کرده اید؟{" "}
            <Link to="/forgotPassword">بازیابی</Link> کنید.
          </p>
          <Link to="/">بازگشت به صفحه اصلی</Link>
        </div>
        <div className="title">
          <h2>خیلی خوبه که برگشتی!</h2>
        </div>
      </div>
    </div>
  );
}
